module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"brands and emotions GREEN","short_name":"brands and emotions GREEN","start_url":"/","lang":"de","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/svg/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a787f8f8988f4c54b1ab8b19f9d56f7d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/techmynder/clients/brandsandemotions/brandsandemotions-green/src/data/i18n","languages":["de"],"defaultLanguage":"de","redirect":true,"redirectDefaultLanguageToRoot":false,"redirectComponent":"/builds/techmynder/clients/brandsandemotions/brandsandemotions-green/src/components/redirect.tsx","ignoredPaths":[],"fallbackLanguage":"de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#12b57b","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/techmynder/clients/brandsandemotions/brandsandemotions-green/src/cms.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
