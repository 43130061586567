import React, { useState } from "react"
import "./polyfills"

export const appContext = React.createContext({
  isNavigationMenuOpened: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setNavigationMenuOpened: (open: boolean) => {},
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Provider = (props: any) => {
  const [isNavigationMenuOpened, setNavigationMenuOpen] = useState(false)

  return (
    <appContext.Provider
      value={{
        isNavigationMenuOpened,
        setNavigationMenuOpened: open => setNavigationMenuOpen(open),
      }}
    >
      {props.children}
    </appContext.Provider>
  )
}

export default Provider
